import { ReplayService, LiveMapService } from "../services";
import { CommonService } from "../service";
import LocationService from "../service/locations.service";
import { ConfigurationService } from "../service";
import { format } from 'date-fns';
const dateFormat = "yyyy-MM-dd";
import { useGroupsFilterStore } from '@/stores/groups-store';
import { useLocationStore } from '@/stores/location-store';
import UserService from "@/service/login.service";
import { useCommonStore } from '@/stores/common-store';
import { useDriverStore } from '@/stores/driver-store';
import { useAssetStore } from '@/stores/asset-store';
import { useConfigStore } from '@/stores/config-store';
import Vue from 'vue';

const state = () => ({
    vehicles: [],
    drivers: [],
    trips: [],
    tollgates: [],
    date: [format(new Date(), dateFormat), format(new Date(), dateFormat)],
    tab: 0,
    search: {
        vehicles: [],
        drivers: []
    },
    searchSelected: {
        vehicles: [],
        drivers: []
    },
    selected: {
        drivers: [],
        vehicles: []
    },
    locations: [],
    selectionLoaded: false,
})

const getters = {
    trips(state) {
        return state.trips;
    },
    vehicles(state) {
        return state.vehicles;
    },
    drivers(state) {
        return state.drivers;
    },
    date(state) {
        return state.date;
    },
    tab(state) {
        return state.tab;
    },
    selected(state) {
        return state.selected
    },
    locations(state) {
        return state.locations
    },
    searchSelected(state) {
        return state.searchSelected
    },
    selectionLoaded(state) {
        return state.selectionLoaded
    }
}

const actions = {
    clearStore({ commit }) {
        commit('clearStore');
    },
    clear({ commit }) {
        commit("setSelectionLoaded", false);
        commit('setVehicles', []);
        commit('setDrivers', []);
        commit('setTrips', []);
        commit('setSelected', {
            drivers: [],
            vehicles: []
        });
         commit('setSearchSelected', {
            drivers: [],
            vehicles: []
        });
        
    },
    clearVehicle({ commit }, payload) {
        commit('clearVehicle', payload);
    },

    async getAssetList({ commit }) {
        const assetStore = useAssetStore();
        const groupFilterStore = useGroupsFilterStore();
        var assets = assetStore.getAssetsSelectorData()
        if (assets.length == 0) {
            return await assetStore.getAssetSelectionData().then((result) => {
                var loadedAssets = assetStore.getAssetsSelectorData();
                loadedAssets = !groupFilterStore.vehicles.includes("ALL")
                ? loadedAssets.filter(i => groupFilterStore.vehicles.includes(i.id))
                : loadedAssets;
                commit('setVehicleSearchList', loadedAssets);
                return loadedAssets;
            });
        } else {
            assets = !groupFilterStore.vehicles.includes("ALL")
                ? assets.filter(i => groupFilterStore.vehicles.includes(i.id))
                : assets;
            commit('setVehicleSearchList', assets);
            return assets;
        };
    },
    async getOperatorList({ commit }) {
        const driverStore = useDriverStore();
        var operators = driverStore.getOperatorsSelectorData()
        if (operators.length == 0) {
            return await driverStore.getOperatorsSelectionData().then((result) => {
                var loadedOperators = driverStore.getOperatorsSelectorData();
                commit('setDriverSearchList', loadedOperators);
                return loadedOperators;
            });
        } else {
            commit('setDriverSearchList', operators);
            return operators;
        };
    },

    async getSearchLists({ dispatch, commit, state }) {  
        
        await Promise.all([
            dispatch("applyGroupFilter", state.searchSelected),
            dispatch('getAssetList'),
            dispatch('getOperatorList')
        ]).then(async ([selection, assetList, operatorList]) => {
            let smallSelection = JSON.parse(JSON.stringify(selection));
            if (assetList.length <= 20 && smallSelection.vehicles == 0) {
                smallSelection.vehicles = assetList.map(i => i.id);
            };
            if (operatorList.length <= 20 && smallSelection.drivers == 0) {
                smallSelection.drivers = operatorList.map(i => i.id);
            };
            if (JSON.stringify(smallSelection) != JSON.stringify(state.searchSelected)
                && (smallSelection.vehicles != 0 || smallSelection.drivers != 0)) {
                try {
                    commit('setSearchSelected', smallSelection);
                } catch (e) {
                };
            };
            //Filter selection
            var replaySelection = state.selected;
            replaySelection.vehicles = replaySelection.vehicles.filter(i => assetList.filter(a => a.id == i).length !== 0);
            replaySelection.drivers = replaySelection.drivers.filter(i => operatorList.filter(a => a.id == i).length !== 0);
            commit('setSelected', replaySelection);
        });
    },
    async getSearchSelected({ state, commit, dispatch }) {
        commit("setSelectionLoaded", false);
        const configStore = useConfigStore();
        let selection = await configStore.getReplaySelection();
        selection = await dispatch("applyGroupFilter", selection);
        if (selection.vehicles.length == 0 && selection.drivers.length == 0) {
            await Promise.resolve(dispatch('getAssetList')).then(assetList => {
                if (assetList.length <= 20) {
                    selection.vehicles = assetList.map(i => i.id);           
                };
            });
        };
        dispatch('applyGroupFilter', selection, true);
        await Promise.resolve(dispatch('getSearchLists'));
        try {
            Vue.prototype.$replayEventHub.$emit('refilter', state.searchSelected);
        } catch (e) {
        };
        commit("setSelectionLoaded", true);
        return selection;

    },
    async applyGroupFilter({ commit }, selection, commitOnComplete = false) {
         const groupFilterStore = useGroupsFilterStore();
        if (!groupFilterStore.vehicles.includes("ALL")) {
            selection.vehicles = selection.vehicles.filter(i => groupFilterStore.vehicles.includes(i));
        };
        if (!groupFilterStore.drivers.includes("ALL")) {
            selection.drivers = selection.drivers.filter(i => groupFilterStore.drivers.includes(i));
        };
        if (commitOnComplete) {
            commit('setSearchSelected', selection);
        }
        return selection;
    },
    updateSearchSelected({ commit }, payload) {
        const configStore = useConfigStore();
        configStore.saveReplaySelection(payload);
        commit('setSearchSelected', payload);
    },
    getTripsFromSelected({ commit, state }, payload) {
        if (state.searchSelected.vehicles.length > 0 || state.searchSelected.drivers.length > 0) {
            payload.request = {
                vehicles: state.searchSelected.vehicles,
                drivers: state.searchSelected.drivers,
            };
            return ReplayService.getTrips(payload).then((result) => {
                console.log(result);
                commit('setVehicles', result.vehicles);
                commit('setDrivers', result.drivers);
                //Attempt Auto Selection
                if (state.tab == 0
                    && result.vehicles.length == 1
                    && result.vehicles[0].events.length !== 0) {
                    commit('setSelected', {
                        drivers: [...state.selected.drivers],
                        vehicles: [result.vehicles[0].details.id]
                    });
                };
                if (state.tab == 1
                    && result.drivers.length == 1
                    && result.drivers[0].events.length !== 0) {
                     commit('setSelected', {
                        drivers: [result.drivers[0].details.id],
                        vehicles: [...state.selected.vehicles]
                    });
                };
                return result;
            });
        }
    },
    getTripsByGlobalFilter({ commit }, payload) {
        const groupFilterStore = useGroupsFilterStore();
        payload.request = {
            vehicles: groupFilterStore.vehicles,
            drivers: groupFilterStore.drivers,
        };
        return ReplayService.getTrips(payload).then((result) => {
            commit('setVehicles', result.vehicles);
            commit('setDrivers', result.drivers);
            return result;
        });
    },
    follow({ }, payload) {
        return UserService.follow(payload);
    },
    getTrips({ commit }, payload) {
        const commonStore = useCommonStore();
        if (commonStore.getTollgates().length == 0) {
            CommonService.getTollgates().then((result) => {
                commit('setTollgates', result);
                commonStore.setTollgates(result);
            });
        } else {
            commit('setTollgates', commonStore.getTollgates());
        };
        return ReplayService.getTrips(payload).then((result) => {
            commit('setVehicles', result.vehicles);
            commit('setDrivers', result.drivers);
            return result;
        });
    },

    getAll({ commit }, payload) {
        const commonStore = useCommonStore();
        if (commonStore.getTollgates().length == 0) {
            CommonService.getTollgates().then((result) => {
                commit('setTollgates', result);
                commonStore.setTollgates(result);
            });
        } else {
            commit('setTollgates', commonStore.getTollgates());
        };
        return ReplayService.getAll(payload).then((result) => {
            commit('setVehicles', result.trips.vehicles);
            commit('setDrivers', result.trips.drivers);
            commit('setTrips', result.details);
            return result;
        });
    },
    updateTripDetails({ commit }, updated) {
        commit('setTrips', updated.trips);
        if (updated.vehicles) {
            commit('setVehicles', updated.vehicles);
        }
        if (updated.drivers) {
            commit('setDrivers', updated.drivers);
        }
    },
    getTripDetails({ commit }, payload) {
        return ReplayService.getTripDetails(payload).then((result) => {
            commit('setTrips', result);
            return result;
        });
    },
    setDate({ commit }, date) {
        commit('setDate', date);
    },
    setTab({ commit }, tab) {
        commit('setTab', tab);
    },
    setSelected({ commit }, selection) {
        commit('setSelected', selection);
    },
    getLocations({ commit }) {
        const locationStore = useLocationStore();
        if (locationStore.getLocationsData().length == 0) {
            LocationService.searchByGroupIds().then(results => {
                commit('setLocations', results.items);
            })
        } else {
            commit('setLocations', locationStore.getLocationsData());
        };    
    },
    saveUserConfig({ commit }, payload) {
        return ConfigurationService.saveUserConfig(payload.id, payload.body).then(() => {
            ///? TODO: Update User Config
        });
    },
}

const mutations = {
    setVehicles(state, vehicles) {
        state.vehicles = vehicles;
    },
    setDrivers(state, drivers) {
        state.drivers = drivers;
    },
    setTrips(state, trips) {
        state.trips = trips;
    },
    setDate(state, date) {
        state.date = date;
    },
    setTab(state, tab) {
        state.tab = tab;
    },
    setVehicleSearchList(state, vehicles) {
        state.search.vehicles = vehicles;
    },
    setDriverSearchList(state, drivers) {
        state.search.drivers = drivers;
    },
    setSearchSelected(state, payload) {
        state.searchSelected.vehicles = [...new Set([...payload.vehicles])];
        state.searchSelected.drivers = [...new Set([...payload.drivers])];
    },
    setSelected(state, selected) {
        state.selected = selected;
    },
    setTollgates(state, tollgates) {
        state.tollgates = tollgates;
    },
    setLocations(state, locations) {
        state.locations = locations
    },
    setSelectionLoaded(state, loadingState) {
        state.selectionLoaded = loadingState
    },
    updateFilter(state, { layers }) {
        ConfigurationService.updateSortingConfig("replay-layers", layers);
    },
    clearStore(state) {
        state.vehicles = [];
        state.drivers = [];
        state.trips = [];
        state.tollgates = [];
        state.date = format(new Date(), dateFormat);
        state.tab = 0;
        state.search = {
            vehicles: [],
            drivers: []
        };
        state.searchSelected = {
            vehicles: [],
            drivers: []
        };
        state.selected = {
            drivers: [],
            vehicles: []
        };
        state.locations = [];
        state.selectionLoaded = false;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
