import DateUtil from './date-util';
import CommonUtil from './common-utils';
import Vue from 'vue';
import Common from "../../constant/common";
import { mapGetters, mapActions } from "vuex";

export default {
    filters: {
        // Temp filters
        convertDistance(value) {
            const unit = "km";
            return Common.convertDistance(value, unit)
        },
        convertTime24to12(value) {
            return Common.convertTime24to12(value);
        },
        convertTime(value) {
            return Common.convertTime(value);
        },
        toHHMMSS(value) {
            return Common.toHHMMSS(value);
        },
        converttime(value) {
            return Common.converttime(value);
        },
        formatAMPM(value) {
            return Common.formatAMPM(value);
        },
        formatDate(value) {
            return Common.formatDate(value);
        },
        formatTime(value) {
            return Common.formatTime(value);
        },
        formatreportDateTime(value) {
            return Common.formatreportDateTime(value);
        },
        convertToUserTimeZone(value) {
            return Common.convertUtcToUserTimeZone(value, "Africa/Johannesburg", "DD-MM-YYYY HH:MM:SS");
        },
        convertToUserTimeZoneYMD(value) {
            return Common.convertUtcToUserTimeZone(value, "Africa/Johannesburg", "YYYY-MM-DD hh:MM A");
        },
        // Temp filters ended
        $convertDistance(value) {
            return CommonUtil.convertDistance(value);
        },
        $convertDistanceNoSuffix(value) {
            return CommonUtil.convertDistanceNoSuffix(value);
        },
        $convertDistanceDecimal(value, suffix) {
            let result = 0;
            if (suffix === undefined || suffix === true) {
                result = CommonUtil.convertDistanceDecimal(value);
            } else {
                result = CommonUtil.getConvertDistanceDecimal(value);
            }
            return (isNaN(value)) ? 0 : result;
        },
        $convertDistanceDecimalNoSuffix(value) {
            let result = 0;
            result = CommonUtil.convertDistanceDecimalNoSuffix(value);
            return (isNaN(value)) ? 0 : result;
        },
        $getConvertedDistance(value) {
            return CommonUtil.getConvertedDistance(value);
        },
        $getConvertedFuel(value) {
            return CommonUtil.getConvertedFuel(value);
        },
        $getConvertedSpeed(value) {
            return CommonUtil.getConvertedSpeed(value);
        },
        $convertSpeed(value) {
            return CommonUtil.convertSpeed(value);
        },
        $convertFuel(value) {
            return CommonUtil.convertFuel(value);
        },
        $getDistanceUnit() {
            return CommonUtil.getDistanceUnit();
        },
        $getFuelUnit() {
            return CommonUtil.getFuelUnit();
        },
        $getSpeedUnit() {
            return CommonUtil.getSpeedUnit();
        },
        $getCurrency() {
            return CommonUtil.getCurrencyUnit();
        },
        $getDefaultLatLng() {
            const configProperties = Vue.prototype.$session.get("configProperties");
            if (!configProperties) {
                return [];
            }
            return [configProperties.latitude, configProperties.longitude];
        },
        $getDefaultZoomLevel() {
            const configProperties = Vue.prototype.$session.get("configProperties");
            if (!configProperties) {
                return 0;
            }
            return configProperties.zoomLevel;
        },
        $formatUTCDateTime(value, targeFormat, sourceFormat) {
            return DateUtil.formatUTCDateTime(value, targeFormat, sourceFormat);
        },
        $formatDateTimeForOrderBy() {
            return DateUtil.formatDateTimeForOrderBy();
        },
        $formatUTCDate(value, targeFormat, sourceFormat) {
            return DateUtil.formatUTCDate(value, targeFormat, sourceFormat);
        },
        $formatUTCTime(value, targeFormat, sourceFormat) {
            return DateUtil.formatUTCTime(value, targeFormat, sourceFormat);
        },
        $formatDateTime(value, targeFormat, sourceFormat) {
            return DateUtil.formatDateTime(value, targeFormat, sourceFormat);
        },
        $formatDate(value, targeFormat, sourceFormat) {

            return DateUtil.formatDate(value, targeFormat, sourceFormat);
        },
        $formatTime(value, targeFormat, sourceFormat) {
            return DateUtil.formatTime(value, targeFormat, sourceFormat);
        },
        $convertUTCDateToMillis(value, targeFormat, sourceFormat) {
            return DateUtil.convertUTCDateToMillis(value, targeFormat, sourceFormat);
        },
        $formatToServiceDate(value, sourceFormat) {
            return DateUtil.formatToServiceDate(value, sourceFormat);
        },
        $convertLocalDateToMillis(value, targeFormat, sourceFormat) {
            return DateUtil.convertLocalDateToMillis(value, targeFormat, sourceFormat);
        },
        $vehicleInfoFormat(vehicle, showFullString = false) {
            return CommonUtil.vehicleInfoFormat(vehicle, showFullString);
        },
        $vehicleInfoFormatGroup(vehicle) {
            return CommonUtil.vehicleInfoFormatGroup(vehicle);
        },
        $driverInfoFormat(driver, showFullString = false) {
            return CommonUtil.driverInfoFormat(driver, showFullString);
        },
        $driverIdFormat(driver) {
            return CommonUtil.driverIdFormat(driver);
        },
        $formatSeconds(value) {
            return Vue.prototype.$moment.utc(value * 1000).format('HH:mm:ss');
        },
        $withDistanceSuffix(value) {
            return `${value} ${CommonUtil.getDistanceUnit()}`;
        },
        $shortString(value) {
            value = String(value).trim();
            return value = value && value.length >= 15 ? `${value.substring(0, 15)}...` : value;
        },
    },
    computed: {
        ...mapGetters('claimsStore', ["getClaims"])
    },
    methods: {
        $convertDistance(value) {
            return this.$options.filters.$convertDistance(value);
        },
        $convertDistanceNoSuffix(value) {
            return this.$options.filters.$convertDistanceNoSuffix(value);
        },
        $convertDistanceDecimal(value, suffix) {
            return this.$options.filters.$convertDistanceDecimal(value, suffix);
        },
        $convertDistanceDecimalNoSuffix(value) {
            return this.$options.filters.$convertDistanceDecimalNoSuffix(value);
        },
        $convertSpeed(value) {
            return this.$options.filters.$convertSpeed(value);
        },
        $convertFuel(value) {
            return this.$options.filters.$convertFuel(value);
        },
        $getDistanceUnit() {
            return this.$options.filters.$getDistanceUnit();
        },
        $getSpeedUnit() {
            return this.$options.filters.$getSpeedUnit();
        },
        $formatDateTimeForOrderBy() {
            return this.$options.filters.$formatDateTimeForOrderBy();
        },
        $getFuelUnit() {
            return this.$options.filters.$getFuelUnit();
        },
        $getConvertedDistance(value) {
            return CommonUtil.getConvertedDistance(value);
        },
        $getConvertedFuel(value) {
            return CommonUtil.getConvertedFuel(value);
        },
        $getConvertedSpeed(value) {
            return CommonUtil.getConvertedSpeed(value);
        },
        $getDefaultLatLng() {
            return this.$options.filters.$getDefaultLatLng();
        },
        $getDefaultZoomLevel() {
            return this.$options.filters.$getDefaultZoomLevel();
        },
        $formatUTCDateTime(value, targeFormat, sourceFormat) {
            return this.$options.filters.$formatUTCDateTime(value, targeFormat, sourceFormat);
        },
        $formatDateTime(value, targeFormat, sourceFormat) {
            return this.$options.filters.$formatDateTime(value, targeFormat, sourceFormat);
        },
        $formatUTCDate(value, targeFormat, sourceFormat) {
            return this.$options.filters.$formatUTCDate(value, targeFormat, sourceFormat);
        },
        $formatDate(value, targeFormat, sourceFormat) {
            return this.$options.filters.$formatDate(value, targeFormat, sourceFormat);
        },
        $formatUTCTime(value, targeFormat, sourceFormat) {
            return this.$options.filters.$formatUTCTime(value, targeFormat, sourceFormat);
        },
        $formatTime(value, targeFormat, sourceFormat) {
            return this.$options.filters.$formatTime(value, targeFormat, sourceFormat);
        },
        $formatToServiceDate(value, sourceFormat) {
            return this.$options.filters.$formatToServiceDate(value, sourceFormat);
        },
        $formatTimeToRegionalFormat(value, includeTime = false) {
            return value
                ? includeTime
                    ? this.$formatUTCDateTime(this.$moment(value).toDate())
                    : this.$formatUTCDate(this.$moment(value).toDate())
                : null;
        },
        $formatUTCTimeToRegionalFormat(value, includeTime = false) {
            return value
                ? includeTime
                    ? this.$formatUTCDateTime(this.$moment.utc(value).toDate())
                    : this.$formatUTCDate(this.$moment.utc(value).toDate())
                : null;
        },
        $convertUTCDateToMillis(value) {
            return this.$options.filters.$convertUTCDateToMillis(value, targeFormat, sourceFormat);
        },
        $convertLocalDateToMillis(value) {
            return this.$options.filters.$convertLocalDateToMillis(value, targeFormat, sourceFormat);
        },
        $convertTo2Decimals(value) {
            return isNaN(parseFloat(value))
                ? parseFloat(0).toFixed(2).replace(',', '.')
                : parseFloat(value).toFixed(2).replace(',', '.')
        },
        $getUserDateFormat(lowerCaseDays = true) {
            return lowerCaseDays
                ? DateUtil.getUserDateFormat().replace('DD', 'dd').replace('YYYY', 'yyyy')
                : DateUtil.getUserDateFormat()
        },
        $getUserTimeFormat() {
            return DateUtil.getUserTimeFormat();
        },
        $getUserDateTimeFormat(lowerCaseDays = true) {
            return lowerCaseDays
                ? DateUtil.getUserDateTimeFormat().replace('DD', 'dd').replace('YYYY', 'yyyy')
                : DateUtil.getUserDateTimeFormat()
        },
        $getServiceDateFormat() {
            return DateUtil.getServiceDateFormat();
        },
        $getServiceTimeFormat() {
            return DateUtil.getServiceTimeFormat();
        },
        $getServiceDateTimeFormat() {
            return DateUtil.getServiceDateTimeFormat();
        },
        $getUserTimeZone() {
            return DateUtil.getUserTimeZone();
        },
        $getUserCurrentTimeZone() {
            return DateUtil.getUserCurrentTimeZone();
        },
        $vehicleInfoFormat(vehicle, showFullString = false) {
            return this.$options.filters.$vehicleInfoFormat(vehicle, showFullString);
        },
        $vehicleInfoFormatGroup(vehicle) {
            return this.$options.filters.$vehicleInfoFormatGroup(vehicle);
        },
        $driverInfoFormat(driver, showFullString = false) {
            return this.$options.filters.$driverInfoFormat(driver, showFullString);
        },
        $driverIdFormat(driver) {
            return this.$options.filters.$driverIdFormat(driver);
        },
        $getCurrency() {
            return this.$options.filters.$getCurrency();
        },
        $setDefaultTimeZone() {
            DateUtil.setDefaultZone();
        },
        $getPrivacy() {
            const configProperties = Vue.prototype.$session.get("configProperties");
            const companyPrivacy = Vue.prototype.$session.get("companyPrivacy");
            if (!configProperties) {
                return '';
            } else {
                if (companyPrivacy) {
                    return configProperties.privacy;
                } else {
                    return companyPrivacy;
                }
            }
        },
        $getLocale() {
            let locale = Vue.localStorage.get('langCulture');
            if (locale === undefined || locale === null) {
                locale = 'en';
            }
            return locale;
        },
        $getLanguage() {
            const configProperties = Vue.prototype.$session.get("configProperties");
            if (!configProperties) {
                return '';
            }
            return configProperties.supportedLanguage;
        },
        $vehicleInfoFormat(vehicle, showFullString = false) {
            return this.$options.filters.$vehicleInfoFormat(vehicle, showFullString);
        },
        $driverInfoFormat(driver, showFullString = false) {
            return this.$options.filters.$driverInfoFormat(driver, showFullString);
        },
        $getUTCDateFromUserTz(millis) {
            const targetFormat = process.env.VUE_APP_DateTimeFormat;
            const userTzDateTime = Vue.prototype.$moment(millis).tz(this.$getUserCurrentTimeZone()).format(targetFormat);
            return Vue.prototype.$moment(userTzDateTime, targetFormat).utc().format(targetFormat);
        },
        $getLandingPage() {

            const configProperties = Vue.prototype.$session.get("configProperties");
            if (!configProperties) {
                return '';
            }
            /* temporary routes until not set in configuration properties */
            let temptRoute = {
                Snapshot: "snapshotRead",
                Replay: "replayRead",
                Livemap: "livemapRead",
                Vehicles: "vehiclesRead",
                Drivers: "driversRead",
                Jobs: "jobsRead",
                LPD: "LPDRead",
                FleetAlerts: "alertActivityRead",
                Analytics: "QlikSenseRead",
                Locations: "locationRead",
            }
            let landingPage = '/'
            if (configProperties.landingPage && temptRoute[configProperties.landingPage]) {
                landingPage = { name: temptRoute[configProperties.landingPage] };
            } else {
                landingPage = configProperties.landingPage ? { name: configProperties.landingPage } : "/";
            }
            return landingPage;
        },
        //$getAllowedNotification() {
        //    const configProperties = Vue.prototype.$session.get("configProperties");
        //    if (!configProperties) {
        //        return '';
        //    }
        //    return configProperties.allowedNotification;
        //},
        //$getAllowedNotificationList() {
        //    const configProperties = Vue.prototype.$session.get("configProperties");
        //    if (!configProperties) {
        //        return '';
        //    }
        //    return configProperties.allowedNotificationList;
        //},
        //$getWorkingHours(value) {
        //    const configProperties = Vue.prototype.$session.get("configProperties");
        //    if (!configProperties) {
        //        return '';
        //    }
        //    if (value == 1) {
        //        const timeFormat = this.$getServiceTimeFormat();
        //        const workinghour = configProperties.fromWorkingHour;
        //        const timeZone = this.$getUserTimeZone();
        //        const zonedTime = this.$moment
        //            .utc(workinghour, timeFormat)
        //            .tz(timeZone)
        //            .format(timeFormat);

        //        return zonedTime;
        //    }
        //    else {
        //        const timeFormat = this.$getServiceTimeFormat();
        //        const workinghour = configProperties.toWorkingHour;
        //        const timeZone = this.$getUserTimeZone();
        //        const zonedTime = this.$moment
        //            .utc(workinghour, timeFormat)
        //            .tz(timeZone)
        //            .format(timeFormat);

        //        return zonedTime;

        //    }
        //},
        $getDateObjectFromUTC(value, sourceFormat) {
            return DateUtil.getDateObjectFromUTC(value, sourceFormat);
        },
        $getDateObjectFromUTCToTimezone(value, sourceFormat) {
            return DateUtil.getDateObjectFromUTCToTimezone(value);
        },
        //$getDefaultDriverWH() {
        //    return CommonUtil.getDefaultDriverWH();
        //},
        // Temp 
        $convertDateToServiceFormat(value) {
            const targetFormat = process.env.VUE_APP_DateTimeFormat;
            if (value instanceof Date) {
                return Vue.prototype.$moment(value).format(targetFormat);
            }
            return Vue.prototype.$moment(String(value), 'x').format(targetFormat);
        },
        $convertServiceDateToMillis(str) {
            return this.$formatUTCDateTime(str, "x");
        },
        $getDisplayVehicleFlagStatus() {
            return CommonUtil.getDisplayVehicleFlagStatus();
        },

        $getStatusFlagDisplay() {
            return CommonUtil.getStatusFlagDisplay();
        },

        $getStatusFlagDetails() {
            return CommonUtil.getStatusFlagDetails();
        },
        $checkRBACAccess(claim) {
            return this.getClaims.includes(claim);
        },
        $getRBACClaims() {
            return this.getClaims;
        },
        $isMainTenant() {
            return Vue.prototype.$session.get('tenantDetails').mainTenantId === Vue.prototype.$session.get('tenantDetails').tenantId;
        },
        $tenantId() {
            return Vue.prototype.$session.get('tenantDetails').tenantId;
        },
		$withDistanceSuffix(value) {
            return this.$options.filters.$withDistanceSuffix(value);
        },

        /* This function retun string current full location */
        $getLastFullLocation(locationDetail) {
            if (locationDetail.distance > 0.0) {
                return ` ${this.$convertDistanceDecimal(locationDetail.distance)} ${locationDetail.direction} ${(this.$tc != null) ? (this.$tc("LiveMapLabels.of")) : (this.tc("LiveMapLabels.of"))} ${locationDetail.name}`;
            } else {
                return ` ${(this.$tc != null) ? (this.$tc("LiveMapLabels.at")) : (this.tc("LiveMapLabels.at"))} ${locationDetail.name}`;
            }
        },
        $getBlobStorageImage(url) {
            if (url && url !== '') {
                let container = '';
                let image = '';
                let version = '';
                if (url.includes('http')) {
                    var blob = url.split('/');
                    blob.splice(0, 3);
                    container = blob.shift();
                    let imageVersion = blob.join('/')
                    image = imageVersion.split('?version=')[0]
                    version = imageVersion.split('?version=')[1]
                    //URL
                    url = `/api/image?container=${container}&image=${image}&version=${version}`
                }
            }
            return url
        },
        $newGuid() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == "x" ? r : r & 3 | 8;
                return v.toString(16);
            });
        },
        $getGroupNameFromId(id) {
            let allGroups = JSON.parse(localStorage.getItem("globalBusinessGroups"));
            let group = allGroups.find(group => group.id === id || group.groupId === id);

            return group ? group.groupName : "Not Found";
        }
    }
}
